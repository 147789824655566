import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col } from "react-bootstrap"
import Section from "../../../components/Section"
import { useStaticQuery, graphql, Link } from "gatsby"
import ImageDownloadRow from "../../../components/ImageDownloadRow"


const IndexPage = () => {

    const data = useStaticQuery(graphql`
        {
            allMbLogosJson {
                edges {
                    node {
                        name
                        filename
                        description
                        background
                        png
                        svg
                    }
                }
            }
        }
    `)

    return (
        <Layout pageTitle="My Boardroom Logos">
            <Row>
                <Col>
                    <Link to="/members/my-boardroom">Back</Link>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={8}>
                    <p className="para mt-3">
                        These images have padding included so there is no need to
                        add more padding to them. Ensure that, when placing
                        these logos on a document, nothing encroaches on the
                        edges of the image.
                    </p>
                </Col>
            </Row>
            {data.allMbLogosJson.edges.map(({ node }, i) => (
                <LogoRow key={i} node={node} />
            ))}
        </Layout>
    )
}

const LogoRow = ({ node }) => {
    return (
        <Row className="mt-5">
            <Col md={6}>
                <Section bg={node.background} centered className="rounded p-2">
                    <img
                        src={`/img/download/logos/${node.filename}.png`}
                        style={{ maxHeight: "250px" }}
                        alt=""
                        className="img-fluid"
                    />
                </Section>
            </Col>
            <Col>
                <h2>{node.name}</h2>
                <p className="para">{node.description}</p>
                {node.png && (
                    <a
                        href={`/img/download/logos/${node.filename}.png`}
                        download={`${node.name}.png`}
                        className="btn btn-primary m-3"
                    >
                        Download PNG
                    </a>
                )}
                {node.svg && (
                    <a
                        href={`/img/download/logos/${node.filename}.svg`}
                        download={`${node.name}.svg`}
                        className="btn btn-primary m-3"
                    >
                        Download SVG
                    </a>
                )}
            </Col>
</Row>
    )
}

export default IndexPage
